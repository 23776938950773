@import "~bootstrap/scss/bootstrap";

.App {
  text-align: center;
}

.headline {
  font-size: 4em !important;
  margin: 0;
  padding: 0;
  font-weight: bold;
  text-align: left;
  padding-left: 20px;
  text-shadow: 2px 0 #000, -2px 0 #000, 0 2px #000, 0 -2px #000,
              1px 1px #000, -1px -1px #000, 1px -1px #000, -1px 1px #000;
}

.logotext {
  text-transform: uppercase;
  font-size: 3em;
  font-weight: bold;
  text-shadow: 2px 0 #404040, -2px 0 #404040, 0 2px #404040, 0 -2px #404040,
              1px 1px #404040, -1px -1px #404040, 1px -1px #404040, -1px 1px #404040;
  color: #FFF;
  word-spacing: 25%;
}

#root {
  background-color: #eee;
}

.App-header {
  background: linear-gradient(
        90deg,
        rgba(255, 0, 0, 1) 0%,
        rgba(255, 154, 0, 1) 10%,
        rgba(208, 222, 33, 1) 20%,
        rgba(79, 220, 74, 1) 30%,
        rgba(63, 218, 216, 1) 40%,
        rgba(47, 201, 226, 1) 50%,
        rgba(28, 127, 238, 1) 60%,
        rgba(95, 21, 242, 1) 70%,
        rgba(186, 12, 248, 1) 80%,
        rgba(251, 7, 217, 1) 90%,
        rgba(255, 0, 0, 1) 100%
    );
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 2vmin);
  color: white;
  height: 100px;
  max-height: 100px;
}

.container {
  ul {
    list-style: none;
    padding: 0;

    li {
      list-style: none;
      text-align: left;
    }
  }
}

.neon-icon {
  min-width: 45px;
  min-height: 45px;
  max-height: 45px;
  max-width: 45px;
  display: inline-block;
  background-color: #000;
  border-radius: 50px;
  margin-right: 20px;
  vertical-align: bottom;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  &.womb {
    background-image: url('./images/neon-womb.png');
  }

  &.ovaries {
    background-image: url('./images/neon-ovaries.png');
  }
}